// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--0ce18";
export var cardBg = "AngularTechStacks-module--cardBg--7afe5";
export var cards = "AngularTechStacks-module--cards--94b2a";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--018ac";
export var description = "AngularTechStacks-module--description--e437e";
export var heading = "AngularTechStacks-module--heading--5916b";
export var iconssCard = "AngularTechStacks-module--iconssCard--6f8bc";
export var nav = "AngularTechStacks-module--nav--b124e";
export var navItem = "AngularTechStacks-module--nav-item--d66d6";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--6b28c";
export var tabData = "AngularTechStacks-module--tabData--a8324";
export var tech = "AngularTechStacks-module--tech--b8d89";
export var techIcon = "AngularTechStacks-module--techIcon--ba9d7";
export var techImg = "AngularTechStacks-module--techImg--a4675";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--851ff";