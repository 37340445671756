import { Script, graphql } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-pages-compoents/Banner"
import Statics from "../components/hire-typescript/Statics"
import Empower from "../components/hire-pages-compoents/WhyShould"
import WhyHireAngular from "../components/hire-pages-compoents/WhyHireAngular"
import Maximize from "../components/hire-pages-compoents/Maximize"
import HowHireAngular from "../components/hire-python-developers/HowHireAngular"
import HireDevelopers from "../components/hire-pages-compoents/HireDevelopers"
import AngularTechStacks from "../components/hire-javascript/AngularTechStacks"
import AngularTechStacksMobile from "../components/hire-javascript/AngularTechStacksMobile"
import AngularIndustries from "../components/hire-pages-compoents/AngularIndustries"
import QuickRead from "../components/hire-laravel-developers/QuickReads"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/SolutionsMobile"
import Success from "../components/hire-pages-compoents/Success"
import Remote from "../components/hire-pages-compoents/Remote"
import Combination from "../components/hire-pages-compoents/FullStackCombination"
import Engineering from "../components/hire-pages-compoents/Engineering"
import Finest from "../components/hire-pages-compoents/Opportunity"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const HireBackend = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const why = data?.strapiPage?.sections[2]
  const maximizeAngular = data?.strapiPage?.sections[3]
  const developers = data?.strapiPage.sections[4]
  const how = data?.strapiPage?.sections[5]
  const rubust = data?.strapiPage?.sections[6]
  const remote = data?.strapiPage?.sections[7]
  const best = data?.strapiPage?.sections[8]
  const TechStacksAngular = data?.strapiPage?.sections[9]
  const cta = data?.strapiPage?.sections[10]
  const industriesAngular = data?.strapiPage?.sections[11]
  const solutions = data?.strapiPage?.sections[12]
  const awesome = data?.strapiPage?.sections[13]
  const quick = data?.strapiPage?.sections[14]
  const faqs = data?.strapiPage?.sections[15]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const devSDetails = [
    {
      name: "Synthia Green",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/synthai_dev_8dbfd95c70.png",
      experience: "5 years of experience",
      desc: "Detail-oriented JavaScript developer known for crafting elegant solutions with clean, efficient code. A creative problem-solver and team player.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_5_c3048ffdfe.svg",
      reviews: "<b>4.5</b>Stars Reviews",
      skills: [
        "Javascript",
        "LAMP",
        "JAM stack",
        "Prisma",
        "Mongoose",
        "Node Js Server",
        "React Native",
        "Meteor Js",
        "Sequelize",
        "Type ORM",
        ,
      ],
    },
    {
      name: "Azher Khalid",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/azher_dev_fb3be07b3d.png",
      experience: "4 years of experience",
      desc: "Innovative JavaScript developer skilled in optimizing code for high performance. A problem-solving expert dedicated to quality and scalability.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.8</b> Stars Reviews",
      skills: [
        "Ionic",
        "JavaScript",
        "GraphQL",
        "Vue Js",
        "React Native",
        "Meteor Js",
        "Electron Js",
        "Socket.io",
        "Node Js",
        "React Js",
     
        "TypeScript",
        ,
      ],
    },
    {
      name: "Lexi James",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/lexi_dev_24f539dd0e.png",
      experience: "3 Years Of Experience",
      desc: "Versatile front-end JavaScript developer with a talent for creating visually appealing, user-friendly interfaces. Passionate learner and adaptable team player.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.9</b> Stars Reviews",
      skills: [
        "Next Js",
        "Express Js",
        "Javascript",
        "Nuxt Js",
        "LAMP",
        "JAMstack",
        "React Native",
        "Meteor Js",
      ],
    },
  ]

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} hireJavascript={true} />
      <Statics />
      <WhyHireAngular strapiData={whyhire} />
      <Engineering strapiData={why} />
      <Maximize strapiData={maximizeAngular} hireBackend={true} />
      <HireDevelopers strapiData={developers} devSDetails={devSDetails} />
      <HowHireAngular strapiData={how} javaSteps={true} />
      <Empower strapiData={rubust} Vueidentify={true} />
      <Remote strapiData={remote} />
      <Combination strapiData={best} javaCom={true} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks strapiData={TechStacksAngular} />
      ) : (
        <AngularTechStacksMobile strapiData={TechStacksAngular} />
      )}
      <Finest strapiData={cta} backendBudget={true} vsJsBanner={true} />
      <AngularIndustries strapiData={industriesAngular} javaIndus={true} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={solutions} />
      ) : (
        <SolutionsMobile strapiData={solutions} />
      )}
      <Success strapiData={awesome} />
      <QuickRead strapiData={quick} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query HireBackend {
    strapiPage(slug: { eq: "hire-javascript-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default HireBackend
