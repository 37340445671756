// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--190b2";
export var cardBg = "AngularTechStacksMobile-module--cardBg--cc36a";
export var cards = "AngularTechStacksMobile-module--cards--3d655";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--6a0c1";
export var description = "AngularTechStacksMobile-module--description--aa4fc";
export var heading = "AngularTechStacksMobile-module--heading--37c93";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--98706";
export var nav = "AngularTechStacksMobile-module--nav--2d37b";
export var navItem = "AngularTechStacksMobile-module--nav-item--25924";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--16eff";
export var tabData = "AngularTechStacksMobile-module--tabData--d0d08";
export var tech = "AngularTechStacksMobile-module--tech--5a071";
export var techIcon = "AngularTechStacksMobile-module--techIcon--c42ae";
export var techImg = "AngularTechStacksMobile-module--techImg--bcd21";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--55646";