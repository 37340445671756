import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./AngularTechStacks.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Tech Stacks",
    tech: [
      {
        name: "MERN",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/MERN_cad10cfdea.svg",
      },
      {
        name: "PERN",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/PERN_6775b74330.svg",
      },
      {
        name: "MEAN",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/MEAN_2f6544352e.svg",
      },
      {
        name: "PEAN",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/PEAN_62399cd96b.svg",
      },
      {
        name: "MEVN",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/MEVN_1bdd8a7c8f.svg",
      },
      {
        name: "PEVN",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/PEVN_8914dfeb9c.svg",
      },
      {
        name: "LAMP",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/LAMP_d0d9a52ff0.svg",
      },
      {
        name: "JAMstack",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jamstack_e3de35ed99.svg",
      },
      {
        name: "Serverless + Node Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/serverless_node_js_552705470c.svg",
      },
      {
        name: "GraphQL + Apollo",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/graphql_apolo_28a8bbc90c.svg",
      },
      {
        name: "React + Next.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_next_js_21c1eca11a.svg",
      },
      {
        name: "MERN + Redux",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/MERN_redux_20ce979983.svg",
      },
    ],
  },
  {
    title: "Frameworks",
    tech: [
      {
        name: "Next Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/next_js_678c656bab.svg",
      },
      {
        name: "Express Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/express_js_d88d76c098.svg",
      },
      {
        name: "Nuxt Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nuxt_js_c2bde8049f.svg",
      },
      {
        name: "Nest Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nest_js_8c36819e7a.svg",
      },
      {
        name: "Serverless",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/serverless_functions_e60362df63.svg",
      },
      {
        name: "Node Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_700ce8f497.svg",
      },
    ],
  },
  {
    title: "Mobile Frameworks",
    tech: [
      {
        name: "Ionic",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ionic_792376a68a.svg",
      },
      {
        name: "React Native",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_bbfc441a92.svg",
      },
      {
        name: "Meteor Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/meteor_js_7c318d242c.svg",
      },
    ],
  },
  {
    title: "Desktop Frameworks",
    tech: [
      {
        name: "Electron Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/electron_js_75166123c8.svg",
      },
    ],
  },
  {
    title: "Libraries",
    tech: [
      {
        name: "Socket.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/socket_dot_io_d72ff3e5a3.svg",
      },
      {
        name: "Node Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_700ce8f497.svg",
      },
      {
        name: "React Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_1154471199.svg",
      },
      {
        name: "Vue Js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_0e0a8fb370.svg",
      },
      {
        name: "TypeScript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/typescript_89b5021e2f.svg",
      },
      {
        name: "GraphQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/graphql_a175b84b25.svg",
      },
    ],
  },
  {
    title: "JavaScript ORMs",
    tech: [
      {
        name: "Sequelize",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sequelize_e2939c5354.svg",
      },
      {
        name: "TypeORM",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/typeorm_acf8e8b345.svg",
      },
      {
        name: "Prisma",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/prisma_88579b0092.svg",
      },
      {
        name: "Mongoose",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongoose_5619f358a9.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="javascriptTech">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`javascriptTech`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                    style={{
                                      height: "60px",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
